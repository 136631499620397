import { ReactElement } from 'react';
import { Field, FieldProps } from 'formik';
import { FormikTextField } from './input/TextField';

export { FormikTextField as TextField };

interface TextFieldProps {
  label?: string;
  name: string;
  type?: string;
  isRequired?: boolean;
  autoComplete?: string;
  placeholder?: string;
}

interface TextSelectProps extends TextFieldProps {
  selectList?: string[];
  defaultSelectLabel?: string;
  disabled?: boolean;
  onChange?: (
    event: React.ChangeEvent<HTMLSelectElement>,
    setFieldValue: (field: string, value: string) => void
  ) => void;
}

export function TextArea({
  label,
  name,
  placeholder,
}: TextFieldProps): ReactElement {
  return (
    <Field name={name} className="w-full">
      {({ field }: FieldProps) => (
        <>
          <p className="mb-1 block">{label}</p>
          <textarea
            placeholder={placeholder}
            className="w-full rounded border border-hlr-form-border bg-white p-2 text-lg"
            {...field}
          />
        </>
      )}
    </Field>
  );
}

export function TextSelect({
  label,
  name,
  isRequired,
  selectList,
  defaultSelectLabel,
  disabled = false,
  onChange,
}: TextSelectProps): ReactElement {
  const handleChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
    setFieldValue: (field: string, value: string) => void
  ) => {
    setFieldValue(name, event.target.value);
    onChange?.(event, setFieldValue);
  };

  return (
    <div className="w-full">
      <Field name={name}>
        {({ field, meta, form }: FieldProps) => (
          <>
            <p className="mb-1 block">
              {label ?? '' + (isRequired ? ' *' : '')}
            </p>
            <select
              {...field}
              className={`w-full rounded border-hlr-form-border text-lg ${
                disabled ? 'pointer-events-none opacity-25' : ''
              }`}
              onChange={(event) => handleChange(event, form.setFieldValue)}
            >
              <option value={''}>{defaultSelectLabel}</option>
              {selectList?.map((key) => (
                <option value={key} key={key}>
                  {key}
                </option>
              ))}
            </select>
            <span className="mt-1 text-custom-red">
              {meta.touched && meta.error}&nbsp;
            </span>
          </>
        )}
      </Field>
    </div>
  );
}
