'use client';
import { Image as ImageType } from '@/modules/apiTypes';
import Image from 'next/image';

interface MediaProps {
  media: ImageType;
  imageAdditionalClasses: string;
  videoAdditionalClasses: string;
  muted?: boolean;
  controls?: boolean;
  sizes?: string;
}

export default function Media({
  media,
  imageAdditionalClasses,
  videoAdditionalClasses,
  muted,
  controls,
  sizes,
}: MediaProps) {
  if (media.mediaType === 'video') {
    return (
      <video
        preload="metadata"
        muted={muted}
        className={videoAdditionalClasses}
        src={media.url}
        controls={controls}
      />
    );
  } else {
    return (
      <Image
        className={imageAdditionalClasses}
        src={media.url}
        alt={media.alt}
        sizes={sizes ?? ''}
        priority
        fill
      />
    );
  }
}
