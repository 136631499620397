'use client';
interface QuantitySelectorProps {
  amount: number;
  sizeVariant?: 'small' | 'big';
  handleIncrementClick(): void;
  handleDecrementClick(): void;
  handleAmountChange(number: number): void;
}

export default function QuantitySelector({
  amount,
  sizeVariant = 'big',
  handleIncrementClick,
  handleDecrementClick,
  handleAmountChange,
}: QuantitySelectorProps) {
  const sizeVariantClassesDecrement =
    sizeVariant === 'big' ? 'md:p-2 md:pl-4 ' : '';
  const sizeVariantClassesInput = sizeVariant === 'big' ? '' : 'md:w-10 ';
  const sizeVariantClassesIncrement =
    sizeVariant === 'big' ? 'md:p-2 md:pr-4 ' : '';

  return (
    <div className="flex flex-row border border-hlr-gunmetal text-xs md:rounded-full md:text-base">
      <button
        type="button"
        onClick={handleDecrementClick}
        className={`hidden rounded-l-full bg-white p-1 pl-2 focus:outline-none md:block md:hover:bg-gray-100 ${sizeVariantClassesDecrement}`}
      >
        -
      </button>
      <input
        value={amount}
        onChange={(e) => handleAmountChange(parseInt(e.target.value))}
        className={`w-12 text-center ${sizeVariantClassesInput}`}
      />
      <button
        type="button"
        onClick={handleIncrementClick}
        className={`hidden rounded-r-full bg-white p-1 pr-2 focus:outline-none md:block md:hover:bg-gray-100 ${sizeVariantClassesIncrement}`}
      >
        +
      </button>
    </div>
  );
}
