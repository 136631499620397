import * as Sentry from '@sentry/nextjs';

import createClient from 'openapi-fetch';
import { paths } from '@/generated/marketEngineApi';
import {
  AdminCreateOrUpdateUserResponse,
  AdminGetUserResponse,
  AdminShippingAlternative,
  ContactRequest,
  ContactRequestV2,
  CreateCategoryRequest,
  CreateCategoryResponse,
  CreateCustomerRequest,
  CreateCustomerResponse,
  CreateProductRequest,
  CreateProductResponse,
  Customer,
  CustomerType,
  DiscountCode,
  GetCategoriesResponse,
  GetCategoryResponse,
  GetCustomerResponse,
  GetCustomersResponse,
  GetDiscountCodeResponse,
  GetDiscountCodesResponse,
  GetOrderHistoryResponse,
  GetProductResponse,
  GetProductsResponse,
  GetShippingAlternativesRequest,
  GetUserResponse,
  GetUsersResponse,
  GetVismaProductResponse,
  GetVismaProductsResponse,
  HeartstarterRequest,
  HeartstarterRequestV2,
  InstructorClassRequest,
  LoginRequest,
  LoginResponse,
  NewDiscountCode,
  NewImageResponse,
  NewOrderRequest,
  NewOrderResponse,
  NewPaymentSessionRequest,
  NewPaymentSessionResponse,
  NewShippingAlternative,
  RegisterUserRequest,
  ResetPasswordRequest,
  SearchResult,
  SetPasswordRequest,
  ShippingAlternative,
  UpdateCategoryRequest,
  UpdateCategoryResponse,
  UpdateCustomerRequest,
  UpdateCustomerResponse,
  UpdateUserResponse,
  UpdateUserRequest,
  UpdateDiscountCode,
  UpdateImageRequest,
  UpdateImageResponse,
  UpdateProductRequest,
  UpdateProductResponse,
  ValidateDiscountCodeRequest,
  ValidatedDiscountCode,
} from '@/modules/apiTypes';
import { addProductsToMapCache } from '@/modules/productMapCache';

const client = createClient<paths>({
  baseUrl: process.env.NEXT_PUBLIC_API_BASE_URL ?? '',
});

const authHeader = (token: string) => {
  return {
    Authorization: `Bearer ${token}`,
  };
};

export async function sendRentHeartstarterRequest(
  data: HeartstarterRequest
): Promise<void> {
  await client.post('/api/rent_request', { body: data });
}

export async function sendLimeContactRequest(
  data: ContactRequest
): Promise<void> {
  await client.post('/api/contact_request', { body: data });
}

export async function sendRentHeartstarterRequestV2(
  data: HeartstarterRequestV2
): Promise<void> {
  await client.post('/api/v2/rent_request', { body: data });
}

export async function sendLimeContactRequestV2(
  data: ContactRequestV2
): Promise<void> {
  await client.post('/api/v2/contact_request', { body: data });
}

export async function sendInstructorClassRequest(
  data: InstructorClassRequest
): Promise<void> {
  await client.post('/api/instructor_class_request', { body: data });
}

export async function getCategoriesRequest(
  selectedCustomer?: Customer,
  authToken?: string,
  includeProducts?: boolean,
  includeInvisible?: boolean
): Promise<GetCategoriesResponse | undefined> {
  if (selectedCustomer && authToken) {
    const response = await client.get('/api/categories', {
      params: {
        query: { includeProducts, include_invisible: includeInvisible },
      },
      headers: authHeader(authToken),
    });
    return response.data;
  }

  const response = await client.get('/api/categories', {
    params: { query: { includeProducts, include_invisible: includeInvisible } },
  });
  return response.data;
}

export async function getCategoryRequest(
  categoryID: string,
  selectedCustomer?: Customer,
  authToken?: string,
  includeAllDescriptions?: boolean,
  includeInvisible = false
): Promise<GetCategoryResponse | undefined> {
  if (selectedCustomer && authToken) {
    const response = await client.get(
      '/api/customers/{customer_id}/categories/{id}',
      {
        params: {
          path: { id: categoryID, customer_id: selectedCustomer.id },
          query: {
            include_all_descriptions: includeAllDescriptions,
            include_invisible: includeInvisible,
          },
        },
        headers: authHeader(authToken),
      }
    );
    if (response.response.status === 200) {
      response.data && addProductsToMapCache(response.data);
      return response.data;
    }
  }

  const response = await client.get('/api/categories/{id}', {
    params: {
      path: { id: categoryID },
      query: {
        include_all_descriptions: includeAllDescriptions,
        include_invisible: includeInvisible,
      },
    },
  });
  if (response.response.status === 200) {
    response.data && addProductsToMapCache(response.data);
    return response.data;
  }
}

export async function getProductsRequest(
  ids?: string[],
  selectedCustomer?: Customer,
  authToken?: string,
  includeAllDescriptions?: boolean,
  includeBundledProductData?: boolean,
  includeInvisible?: boolean
): Promise<GetProductsResponse | undefined> {
  const params = {
    include_all_descriptions: includeAllDescriptions,
    include_bundle_data: includeBundledProductData,
    include_invisible: includeInvisible,
  };
  const paramString = ids?.join(',');
  const queryParams = paramString
    ? {
        id: paramString,
        ...params,
      }
    : params;
  if (selectedCustomer && authToken) {
    const response = await client.get('/api/customers/{customer_id}/products', {
      params: {
        path: { customer_id: selectedCustomer.id },
        query: queryParams,
      },
      headers: authHeader(authToken),
    });
    return response.data;
  }
  if (authToken) {
    const response = await client.get('/api/products', {
      params: {
        query: queryParams,
      },
      headers: authHeader(authToken),
    });
    return response.data;
  }
  const response = await client.get('/api/products', {
    params: {
      query: queryParams,
    },
  });
  return response.data;
}

export async function getProductRequest(
  id: string,
  selectedCustomer?: Customer,
  authToken?: string,
  includeAllDescriptions?: boolean,
  includeBundledProductData?: boolean,
  includeInvisible?: boolean
): Promise<GetProductResponse | undefined> {
  const queryParams = {
    include_all_descriptions: includeAllDescriptions,
    include_bundle_data: includeBundledProductData,
    include_invisible: includeInvisible,
  };
  if (selectedCustomer && authToken) {
    const response = await client.get(
      '/api/customers/{customer_id}/products/{product_id}',
      {
        params: {
          path: { customer_id: selectedCustomer.id, product_id: id },
          query: queryParams,
        },
        headers: authHeader(authToken),
      }
    );
    return response.data;
  }
  if (authToken) {
    const response = await client.get('/api/products/{id}', {
      params: {
        path: { id: id },
        query: queryParams,
      },
      headers: authHeader(authToken),
    });
    return response.data;
  }
  const response = await client.get('/api/products/{id}', {
    params: {
      path: { id: id },
      query: queryParams,
    },
  });
  return response.data;
}

export async function newPaymentSession(
  data: NewPaymentSessionRequest,
  selectedCustomer?: Customer,
  authToken?: string
): Promise<NewPaymentSessionResponse | undefined> {
  try {
    if (selectedCustomer && authToken) {
      const response = await client.post(
        `/api/customers/{customer_id}/klarna_payment_sessions`,
        {
          params: {
            path: { customer_id: selectedCustomer.id },
          },
          headers: authHeader(authToken),
          body: data,
        }
      );
      if (response.response.status === 201) {
        return response.data;
      }
    } else {
      const response = await client.post('/api/klarna_payment_sessions', {
        body: data,
      });
      if (response.response.status === 201) {
        return response.data;
      }
    }
  } catch (e) {
    Sentry.captureException(e);
  }
}

export async function newOrder(
  data: NewOrderRequest,
  selectedCustomer?: Customer,
  authToken?: string
): Promise<NewOrderResponse | undefined> {
  try {
    if (selectedCustomer && authToken) {
      const response = await client.post(
        `/api/customers/{customer_id}/orders`,
        {
          params: {
            path: { customer_id: selectedCustomer.id },
          },
          headers: authHeader(authToken),
          body: data,
        }
      );
      if (response.response.status === 201) {
        return response.data;
      }
    } else {
      const response = await client.post('/api/orders', { body: data });
      if (response.response.status === 201) {
        return response.data;
      }
    }
  } catch (e) {
    Sentry.captureException(e);
  }
}

export async function loginUser(
  data: LoginRequest
): Promise<LoginResponse | undefined> {
  try {
    const response = await client.post('/api/user/authenticate', {
      body: data,
    });
    if (response.response.status === 200) {
      return response.data;
    }
  } catch (e) {}
}

export type RegisterUserResponse = 'success' | 'failed' | 'conflict';

export async function registerUser(
  data: RegisterUserRequest
): Promise<RegisterUserResponse> {
  try {
    const response = await client.post('/api/users', { body: data });
    if (response.response.status === 200) {
      return 'success';
    }
    if (response.response.status === 409) {
      return 'conflict';
    }
  } catch (e) {
    Sentry.captureException(e);
  }
  return 'failed';
}

export async function resetPassword(data: ResetPasswordRequest): Promise<void> {
  try {
    await client.post('/api/user/reset_password', { body: data });
  } catch (e) {}
}

export async function getUser(
  token: string
): Promise<GetUserResponse | undefined> {
  try {
    const response = await client.get('/api/user', {
      headers: authHeader(token),
    });
    return response.data;
  } catch (e) {
    Sentry.captureException(e);
  }
}

export async function getUserAdmin(
  token: string,
  userId: string
): Promise<AdminGetUserResponse | undefined> {
  try {
    const response = await client.get('/api/users/{user_id}', {
      headers: authHeader(token),
      params: { path: { user_id: userId } },
    });
    return response.data;
  } catch (e) {
    Sentry.captureException(e);
  }
}

export async function getUsers(
  authToken: string,
  email?: string,
  page?: number,
  pageSize?: number
): Promise<GetUsersResponse | undefined> {
  try {
    const response = await client.get('/api/users', {
      headers: authHeader(authToken),
      params: {
        query: {
          email: email,
          page: page,
          page_size: pageSize,
        },
      },
    });
    return response.data;
  } catch (e) {
    Sentry.captureException(e);
  }
}

export async function createOrUpdateUser(
  authToken: string,
  customerId: string,
  email: string
): Promise<AdminCreateOrUpdateUserResponse | undefined> {
  try {
    const response = await client.post('/api/customers/{customer_id}/users', {
      headers: authHeader(authToken),
      params: { path: { customer_id: customerId } },
      body: {
        email: email,
      },
    });
    return response.data;
  } catch (e) {
    Sentry.captureException(e);
  }
}

export async function deleteUserFromCustomer(
  authToken: string,
  customerId: string,
  userId: string
): Promise<'success' | 'failed' | undefined> {
  try {
    const response = await client.del(
      '/api/customers/{customer_id}/users/{user_id}',
      {
        headers: authHeader(authToken),
        params: { path: { customer_id: customerId, user_id: userId } },
      }
    );
    if (response.response.status === 200) {
      return 'success';
    }
    return 'failed';
  } catch (e) {
    Sentry.captureException(e);
  }
}

export type SetPasswordResponse = 'success' | 'failed';

export async function setPassword(
  userID: string,
  data: SetPasswordRequest
): Promise<SetPasswordResponse> {
  try {
    const response = await client.post(`/api/users/{user_id}/credentials`, {
      body: data,
      params: { path: { user_id: userID } },
    });
    if (response.response.status === 200) {
      return 'success';
    }
  } catch (e) {}
  return 'failed';
}

export async function checkResetPasswordToken(
  userID: string,
  data: SetPasswordRequest
): Promise<SetPasswordResponse> {
  try {
    const response = await client.post(
      `/api/users/{user_id}/credentials_verification`,
      {
        body: data,
        params: { path: { user_id: userID } },
      }
    );
    if (response.response.status === 200) {
      return 'success';
    }
  } catch (e) {
    Sentry.captureException(e);
  }
  return 'failed';
}

export async function createCustomer(
  data: CreateCustomerRequest,
  authToken: string
): Promise<CreateCustomerResponse | undefined> {
  try {
    const response = await client.post(`/api/customers`, {
      body: data,
      headers: authHeader(authToken),
    });
    if (response.response.status === 201) {
      return response.data;
    }
  } catch (e) {
    Sentry.captureException(e);
  }
}

export async function updateCustomer(
  data: UpdateCustomerRequest,
  authToken: string,
  id: string
): Promise<UpdateCustomerResponse | undefined> {
  try {
    const response = await client.put(`/api/customers/{customer_id}`, {
      body: data,
      params: { path: { customer_id: id } },
      headers: authHeader(authToken),
    });
    if (response.response.status === 200) {
      return response.data;
    }
  } catch (e) {
    Sentry.captureException(e);
  }
}

export async function updateUser(
  data: UpdateUserRequest,
  authToken: string,
  id: string
): Promise<UpdateUserResponse | undefined> {
  try {
    const response = await client.put(`/api/users/{user_id}`, {
      body: data,
      params: { path: { user_id: id } },
      headers: authHeader(authToken),
    });
    if (response.response.status === 200) {
      return response.data;
    }
  } catch (e) {
    Sentry.captureException(e);
  }
}

export async function getCustomers(
  authToken: string,
  vismaCustomerId?: string,
  companyName?: string,
  organizationNumber?: string,
  page?: number,
  pageSize?: number,
  customerType?: CustomerType
): Promise<GetCustomersResponse | undefined> {
  try {
    const response = await client.get('/api/customers', {
      headers: authHeader(authToken),
      params: {
        query: {
          visma_customer_id: vismaCustomerId,
          company_name: companyName,
          organization_number: organizationNumber,
          page: page,
          page_size: pageSize,
          customer_type: customerType,
        },
      },
    });
    return response.data;
  } catch (e) {
    Sentry.captureException(e);
  }
}

export async function getCustomer(
  authToken: string,
  customerId: string
): Promise<GetCustomerResponse | undefined> {
  try {
    const response = await client.get('/api/customers/{customer_id}', {
      headers: authHeader(authToken),
      params: { path: { customer_id: customerId } },
    });
    return response.data;
  } catch (e) {
    Sentry.captureException(e);
  }
}

export async function getOrderHistory(
  authToken: string
): Promise<GetOrderHistoryResponse | undefined> {
  try {
    const response = await client.get('/api/orders', {
      headers: authHeader(authToken),
    });
    if (response.response.status === 200) {
      return response.data;
    }
  } catch (e) {
    Sentry.captureException(e);
  }
}

export async function updateCategory(
  categoryID: string,
  authToken: string,
  data: UpdateCategoryRequest
): Promise<UpdateCategoryResponse | undefined> {
  try {
    const response = await client.put(`/api/categories/{id}`, {
      body: data,
      params: { path: { id: categoryID } },
      headers: authHeader(authToken),
    });
    if (response.response.status === 200) {
      return response.data;
    }
  } catch (e) {
    Sentry.captureException(e);
  }
}

export async function createCategory(
  authToken: string,
  data: CreateCategoryRequest
): Promise<CreateCategoryResponse | undefined> {
  try {
    const response = await client.post(`/api/categories`, {
      body: data,
      headers: authHeader(authToken),
    });
    if (response.response.status === 200) {
      return response.data;
    }
  } catch (e) {
    Sentry.captureException(e);
  }
}

export async function createProduct(
  authToken: string,
  data: CreateProductRequest
): Promise<CreateProductResponse | undefined> {
  try {
    const response = await client.post(`/api/products`, {
      body: data,
      headers: authHeader(authToken),
    });
    if (response.response.status === 200) {
      return response.data;
    }
  } catch (e) {
    Sentry.captureException(e);
  }
}

export async function updateProduct(
  productID: string,
  authToken: string,
  data: UpdateProductRequest
): Promise<UpdateProductResponse | undefined> {
  try {
    const response = await client.put(`/api/products/{id}`, {
      body: data,
      params: { path: { id: productID } },
      headers: authHeader(authToken),
    });
    if (response.response.status === 200) {
      return response.data;
    }
  } catch (e) {
    Sentry.captureException(e);
  }
}

export async function getVismaProducts(
  authToken: string
): Promise<GetVismaProductsResponse | undefined> {
  try {
    const response = await client.get(`/api/visma_products`, {
      headers: authHeader(authToken),
    });
    if (response.response.status === 200) {
      return response.data;
    }
  } catch (e) {
    Sentry.captureException(e);
  }
}

export async function getVismaProduct(
  authToken: string,
  productId: string
): Promise<GetVismaProductResponse | undefined> {
  try {
    const response = await client.get(`/api/visma_products/{product_id}`, {
      headers: authHeader(authToken),
      params: { path: { product_id: productId } },
    });
    if (response.response.status === 200) {
      return response.data;
    }
  } catch (e) {
    Sentry.captureException(e);
  }
}

export async function createImage(
  fileName: string,
  authToken: string
): Promise<NewImageResponse | undefined> {
  try {
    const { data, response } = await client.post('/api/images', {
      headers: authHeader(authToken),
      body: {
        original_file_name: fileName,
      },
    });
    if (response.status === 200) {
      return data;
    }
  } catch (e) {
    Sentry.captureException(e);
  }
}

export async function updateImage(
  id: number,
  body: UpdateImageRequest,
  authToken: string
): Promise<UpdateImageResponse | undefined> {
  try {
    const { data, response } = await client.patch('/api/images/{id}', {
      headers: authHeader(authToken),
      params: {
        path: {
          id: id.toString(),
        },
      },
      body,
    });
    if (response.status === 200) {
      return data;
    }
  } catch (e) {
    Sentry.captureException(e);
  }
}

export async function uploadImage(
  file: File,
  authToken: string
): Promise<number | null> {
  const newImage = await createImage(file.name, authToken);
  if (newImage === undefined) {
    return null;
  }
  try {
    const uploadResponse = await fetch(
      decodeURIComponent(newImage.upload_url),
      {
        method: 'PUT',
        body: file,
      }
    );
    if (uploadResponse.status !== 200) {
      Sentry.captureMessage('Failed to upload image');
      return null;
    }
  } catch (e) {
    Sentry.captureException(e);
    return null;
  }

  const updateResult = await updateImage(
    newImage.id,
    {
      name: file.name,
    },
    authToken
  );

  if (updateResult !== undefined) {
    return newImage.id;
  }
  return null;
}

export async function getShippingAlternatives(
  data: GetShippingAlternativesRequest
): Promise<ShippingAlternative[] | undefined> {
  try {
    const response = await client.post('/api/shipping', {
      body: data,
    });
    if (response.response.status === 200) {
      return response.data?.alternatives;
    }
  } catch (e) {
    Sentry.captureException(e);
  }
}

export async function getAllShippingAlternatives(
  authToken: string
): Promise<AdminShippingAlternative[] | undefined> {
  try {
    const response = await client.get(`/api/shipping_alternatives`, {
      headers: authHeader(authToken),
    });
    if (response.response.status === 200) {
      return response.data;
    }
  } catch (e) {
    Sentry.captureException(e);
  }
}

export async function createShippingAlternative(
  authToken: string,
  data: NewShippingAlternative
): Promise<NewShippingAlternative | undefined> {
  try {
    const response = await client.post(`/api/shipping_alternatives`, {
      headers: authHeader(authToken),
      body: data,
    });
    if (response.response.status === 201) {
      return response.data;
    }
  } catch (e) {
    Sentry.captureException(e);
  }
}

export async function updateShippingAlternative(
  id: number,
  authToken: string,
  data: NewShippingAlternative
): Promise<NewShippingAlternative | undefined> {
  try {
    const response = await client.patch(`/api/shipping_alternatives/{id}`, {
      headers: authHeader(authToken),
      body: data,
      params: { path: { id: id } },
    });
    if (response.response.status === 200) {
      return response.data;
    }
  } catch (e) {
    Sentry.captureException(e);
  }
}

export async function searchStore(
  searchString: string
): Promise<SearchResult | undefined> {
  try {
    const { response, data } = await client.get('/api/shop', {
      params: {
        query: {
          search: searchString,
        },
      },
    });
    if (response.status === 200) {
      return data;
    }
  } catch (e) {
    Sentry.captureException(e);
  }
}

export async function searchStoreAsCustomer(
  searchString: string,
  customerId: string,
  authToken: string
): Promise<SearchResult | undefined> {
  try {
    const { response, data } = await client.get(
      '/api/customers/{customer_id}/shop',
      {
        headers: authHeader(authToken),
        params: {
          query: {
            search: searchString,
          },
          path: {
            customer_id: customerId,
          },
        },
      }
    );
    if (response.status === 200) {
      return data;
    }
  } catch (e) {
    Sentry.captureException(e);
  }
}

export async function createDiscountCode(
  authToken: string,
  data: NewDiscountCode
): Promise<DiscountCode | undefined> {
  try {
    const response = await client.post(`/api/discount_codes`, {
      body: data,
      headers: authHeader(authToken),
    });
    if (response.response.status === 201) {
      return response.data;
    }
  } catch (e) {
    Sentry.captureException(e);
  }
}

export async function updateDiscountCode(
  code: string,
  authToken: string,
  data: UpdateDiscountCode
): Promise<DiscountCode | undefined> {
  try {
    const response = await client.patch(`/api/discount_codes/{code}`, {
      body: data,
      params: { path: { code: code } },
      headers: authHeader(authToken),
    });
    if (response.response.status === 200) {
      return response.data;
    }
  } catch (e) {
    Sentry.captureException(e);
  }
}

export async function getDiscountCodesRequest(
  authToken: string,
  page?: number,
  pageSize?: number
): Promise<GetDiscountCodesResponse | undefined> {
  try {
    const response = await client.get(`/api/discount_codes`, {
      headers: authHeader(authToken),
      params: {
        query: {
          page: page,
          page_size: pageSize,
        },
      },
    });
    if (response.response.status === 200) {
      return response.data;
    }
  } catch (e) {
    Sentry.captureException(e);
  }
}

export async function getDiscountCode(
  authToken: string,
  code: string
): Promise<GetDiscountCodeResponse | undefined> {
  try {
    const response = await client.get('/api/discount_codes/{code}', {
      headers: authHeader(authToken),
      params: { path: { code: code } },
    });
    if (response.response.status === 200) {
      return response.data;
    }
  } catch (e) {
    Sentry.captureException(e);
  }
}

export async function deleteDiscountCode(
  authToken: string,
  code: string
): Promise<'success' | 'failed'> {
  try {
    const response = await client.del('/api/discount_codes/{code}', {
      headers: authHeader(authToken),
      params: { path: { code: code } },
    });
    if (response.response.status === 204) {
      return 'success';
    }
  } catch (e) {
    Sentry.captureException(e);
  }
  return 'failed';
}

export async function validateDiscountCode(
  payload: ValidateDiscountCodeRequest
): Promise<ValidatedDiscountCode | undefined> {
  try {
    const { response, data } = await client.post(
      '/api/discount_codes/validation',
      {
        body: payload,
      }
    );
    if (response.status === 200) {
      return data;
    } else {
      return undefined;
    }
  } catch (e) {
    Sentry.captureException(e);
    throw e;
  }
}

export async function validateDiscountCodeForCompanyCustomer(
  payload: ValidateDiscountCodeRequest,
  selectedCustomer?: Customer,
  authToken?: string
): Promise<ValidatedDiscountCode | undefined> {
  if (authToken && selectedCustomer) {
    try {
      const { response, data } = await client.post(
        '/api/customers/{customer_id}/discount_codes/validation',
        {
          body: payload,
          params: {
            path: { customer_id: selectedCustomer.id },
          },
          headers: authHeader(authToken),
        }
      );
      if (response.status === 200) {
        return data;
      } else {
        return undefined;
      }
    } catch (e) {
      Sentry.captureException(e);
      throw e;
    }
  }
}
