'use client';
import {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLocalStorageState } from '@/modules/localStorage';
import { getCategoriesRequest } from '@/modules/apiClient';
import { useUserContext } from '@/context/UserContext';
import {
  ProductAmount,
  ShippingAlternative,
  ShoppingCartProduct,
  WebshopCategory,
  WebshopProduct,
} from '@/modules/apiTypes';
import { getProducts } from '@/modules/productMapCache';
import { setCachedCategories } from '@/modules/categoryCache';
import { mapCategories } from '@/utils/storeUtils';
import * as Sentry from '@sentry/nextjs';

interface ShoppingCartContextProps {
  noItemsInCart: number;
  shoppingCart: ShoppingCart;
  updateShoppingCart: Dispatch<ShoppingCartActionProps>;
  emptyShoppingCart(): void;
  displayVat: boolean;
  setDisplayVat: Dispatch<SetStateAction<boolean>>;
  categoryMap: Map<string, WebshopCategory>;
  shoppingCartProductAmount: ProductAmount[] | undefined;
  selectedShippingAlternative: ShippingAlternative | undefined;
  setSelectedShippingAlternative: Dispatch<
    SetStateAction<ShippingAlternative | undefined>
  >;
}

export enum ShoppingCartAction {
  ADD = 'ADD',
  MODIFY = 'MODIFY',
  DELETE = 'DELETE',
}

export interface ShoppingCartActionProps {
  type: ShoppingCartAction;
  payload: ShoppingCartProduct;
}

interface ShoppingCart {
  content: ShoppingCartProduct[];
}

export type WebshopProductMap = Map<string, WebshopProduct>;
export type WebshopCategoryMap = Map<string, WebshopCategory>;

export const ShoppingCart = createContext<ShoppingCartContextProps>({
  noItemsInCart: 0,
  shoppingCart: { content: [] },
  updateShoppingCart: () => null,
  emptyShoppingCart: () => null,
  displayVat: true,
  setDisplayVat: () => undefined,
  categoryMap: new Map<string, WebshopCategory>(),
  shoppingCartProductAmount: undefined,
  selectedShippingAlternative: undefined,
  setSelectedShippingAlternative: () => null,
});

function getNoItemsInCart(shoppingCart: ShoppingCart) {
  return shoppingCart.content.reduce((sum, { amount }) => sum + amount, 0);
}

export const useShoppingCart = (): ShoppingCartContextProps =>
  useContext(ShoppingCart);

const SHOPPING_CART_KEY = 'shoppingCart';
const VAT_KEY = 'displayVat';

export function ShoppingCartContext({
  children,
}: Readonly<{
  children: React.ReactNode;
}>) {
  const [shoppingCart, setShoppingCart] = useLocalStorageState<ShoppingCart>(
    SHOPPING_CART_KEY,
    { content: [] }
  );

  const updateShoppingCart = useCallback(
    (action: ShoppingCartActionProps) => {
      setShoppingCart((state) => updateCart(state, action));
    },
    [setShoppingCart]
  );

  const emptyShoppingCart = useCallback(() => {
    setShoppingCart({ content: [] });
  }, [setShoppingCart]);

  const [displayVat, setDisplayVat] = useLocalStorageState(VAT_KEY, true);
  const [categoryMap, setCategoryMap] = useState<WebshopCategoryMap>(new Map());
  const [selectedShippingAlternative, setSelectedShippingAlternative] =
    useState<ShippingAlternative>();

  const { selectedCustomer, authToken } = useUserContext();
  const [shoppingCartProductAmount, setShoppingCartProductAmount] =
    useState<ProductAmount[]>();

  useEffect(() => {
    let isInvalidated = false;

    const fetch = async () => {
      try {
        const products = await getProducts(
          shoppingCart.content.map((i) => i.id),
          selectedCustomer,
          authToken
        );

        if (isInvalidated) {
          return;
        }

        const webshopProds = products.map((item) => ({
          product: item,
          amount:
            shoppingCart.content.find((c) => c.id === item.id.toString())
              ?.amount ?? 0,
        }));

        const simplifiedCart = products.map((item) => ({
          amount:
            shoppingCart.content.find((c) => c.id == item.id.toString())
              ?.amount ?? 0,
          id: item.id.toString(),
        }));

        if (!compareCarts(shoppingCart.content, simplifiedCart)) {
          setShoppingCart({ content: simplifiedCart });
        }

        setShoppingCartProductAmount(webshopProds);
      } catch (e) {
        Sentry.captureException(e);
      }
    };

    void fetch();

    return () => {
      isInvalidated = true;
    };
  }, [authToken, selectedCustomer, shoppingCart.content, setShoppingCart]);

  function compareCarts(l1: ShoppingCartProduct[], l2: ShoppingCartProduct[]) {
    if (l1.length !== l2.length) {
      return false;
    }

    const sortedL1 = l1.slice().sort();
    const sortedL2 = l2.slice().sort();

    return JSON.stringify(sortedL1) === JSON.stringify(sortedL2);
  }

  useEffect(() => {
    let isInvalidated = false;

    async function fetchData() {
      const categoryData = await getCategoriesRequest(
        selectedCustomer,
        authToken
      );
      if (!isInvalidated && categoryData?.categories) {
        setCachedCategories(categoryData.categories);
        setCategoryMap(mapCategories(categoryData.categories));
      }
    }

    void fetchData();

    return () => {
      isInvalidated = true;
    };
  }, [selectedCustomer, authToken]);

  const value = useMemo(() => {
    return {
      noItemsInCart: getNoItemsInCart(shoppingCart),
      updateShoppingCart,
      emptyShoppingCart,
      shoppingCart,
      displayVat,
      setDisplayVat,
      categoryMap,
      shoppingCartProductAmount,
      selectedShippingAlternative,
      setSelectedShippingAlternative,
    };
  }, [
    shoppingCart,
    updateShoppingCart,
    emptyShoppingCart,
    displayVat,
    setDisplayVat,
    categoryMap,
    shoppingCartProductAmount,
    selectedShippingAlternative,
  ]);

  return (
    <ShoppingCart.Provider value={value}>{children}</ShoppingCart.Provider>
  );
}

const updateCart = (state: ShoppingCart, action: ShoppingCartActionProps) => {
  const { type, payload } = action;
  switch (type) {
    case ShoppingCartAction.ADD:
      return { ...addToCart(state, payload) };
    case ShoppingCartAction.MODIFY:
      return { ...modifyCart(state, payload) };
    case ShoppingCartAction.DELETE:
      return { ...deleteFromCart(state, payload) };
    default:
      return { ...state };
  }
};

function addToCart(
  cart: ShoppingCart,
  cartData: ShoppingCartProduct
): ShoppingCart {
  const idx = cart.content.findIndex((prod) => prod.id === cartData.id);
  const newContent = [...cart.content];
  if (idx > -1) {
    const amount = newContent[idx].amount + cartData.amount;
    newContent[idx] = { ...cart.content[idx], amount };
  } else {
    newContent.push(cartData);
  }
  return { ...cart, content: newContent };
}

function modifyCart(
  cart: ShoppingCart,
  cartData: ShoppingCartProduct
): ShoppingCart {
  const idx = cart.content.findIndex((prod) => prod.id === cartData.id);
  const newContent = [...cart.content];
  if (idx > -1) {
    const amount = cartData.amount;
    newContent[idx] = { ...cart.content[idx], amount };
  }
  return { ...cart, content: newContent };
}

function deleteFromCart(
  cart: ShoppingCart,
  cartData: ShoppingCartProduct
): ShoppingCart {
  const idx = cart.content.findIndex((prod) => prod.id === cartData.id);
  const newContent = [...cart.content];
  if (idx > -1) {
    newContent.splice(idx, 1);
  }
  return { ...cart, content: newContent };
}
