import { getNames, registerLocale } from 'i18n-iso-countries';
import svLocale from 'i18n-iso-countries/langs/sv.json';
import { Field, FieldProps } from 'formik';
import { useMemo } from 'react';

interface CountrySelectorProps {
  label?: string;
  name: string;
  locale: string;
  isRequired?: boolean;
  autoComplete?: string;
  value?: string;
}

export default function CountrySelector({
  label,
  name,
  locale,
  isRequired,
  autoComplete,
  value = 'SE',
}: CountrySelectorProps) {
  registerLocale(svLocale);
  const countryOptions = useMemo(
    () => Object.entries(getNames(locale)),
    [locale]
  );

  return (
    <Field name={name} className="w-full">
      {({ field, meta }: FieldProps) => (
        <>
          <p className="mb-1 block">{label ?? '' + (isRequired ? ' *' : '')}</p>
          <select
            {...field}
            autoComplete={autoComplete}
            className="w-full rounded border border-hlr-form-border p-2 text-lg"
            defaultValue={value}
          >
            {countryOptions.map(([countryCode, countryName]) => (
              <option value={countryCode} key={countryCode}>
                {countryName}
              </option>
            ))}
          </select>
          <span className="mt-1 text-custom-red">
            {meta.touched && meta.error}&nbsp;
          </span>
        </>
      )}
    </Field>
  );
}
