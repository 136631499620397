import { Dispatch, ReactElement, useState } from 'react';
import { Modal, ModalButton } from '@/components/general/Modal';
import { Formik } from 'formik';
import { TextField } from '@/components/general/TextFields';
import { loginUser } from '@/modules/apiClient';
import * as Yup from 'yup';
import { useUserContext } from '@/context/UserContext';
import { StrapiLoginFormAttributes } from '@/modules/strapiTypes';
import { ModalVisibility } from './NavbarModal';

function getValidationSchema(texts: StrapiLoginFormAttributes) {
  return Yup.object({
    email: Yup.string()
      .email(texts?.invalid_email_error_message)
      .required(texts?.required_field_error_message),
    password: Yup.string().required(texts?.required_field_error_message),
  });
}

export interface LoginValues {
  email: string;
  password: string;
}

const initialValues: LoginValues = {
  email: '',
  password: '',
};

interface LoginModalProps {
  onHide: () => void;
  setVisibility: Dispatch<ModalVisibility>;
  texts: StrapiLoginFormAttributes;
}

export function LoginModal({
  onHide,
  setVisibility,
  texts,
}: LoginModalProps): ReactElement | null {
  const { setAuthToken, setUserLoginAlert } = useUserContext();
  const [submitFailed, setSubmitFailed] = useState(false);

  const handleSubmit = async ({ email, password }: LoginValues) => {
    setSubmitFailed(false);

    const loginResponse = await loginUser({ email, password });
    if (!loginResponse) {
      setSubmitFailed(true);
    } else {
      setAuthToken(loginResponse.token);
      onHide();
      setUserLoginAlert(true);
    }
  };
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={getValidationSchema(texts)}
      validateOnMount={true}
    >
      {({ submitForm, isSubmitting, isValid }) => (
        <Modal
          onHide={onHide}
          title={texts?.title ?? 'Logga in'}
          footer={
            <div className="flex w-full items-center justify-between">
              <p className="text-custom-red">
                {submitFailed && texts?.login_failed_error_message}
              </p>
              <div className="shrink-0">
                <ModalButton
                  text={texts?.submit_button_label ?? ''}
                  disabled={isSubmitting || !isValid}
                  onClick={() => void submitForm()}
                />
              </div>
            </div>
          }
        >
          <div className="px-0 md:px-8">
            <p className="mb-4">
              {texts?.registration_text + ' '}
              <a
                className="font-bold hover:underline"
                onClick={() => setVisibility(ModalVisibility.Registration)}
                href="#"
              >
                {texts?.registration_link_label}
              </a>
            </p>
            <p className="mb-4 text-lg font-semibold">{texts?.login_header}</p>
            <div className="flex flex-col gap-2">
              <TextField
                label={texts?.email_label}
                name="email"
                isRequired={true}
                autoComplete="email"
              />
              <TextField
                label={texts?.password_label}
                name="password"
                type="password"
                isRequired={true}
                autoComplete="current-password"
              />
              <p className="mb-4">
                {texts?.password_recovery_text + ' '}
                <a
                  className="font-bold hover:underline"
                  onClick={() => setVisibility(ModalVisibility.PasswordReset)}
                  href="#"
                >
                  {texts?.password_recovery_link_label}
                </a>
              </p>
            </div>
          </div>
        </Modal>
      )}
    </Formik>
  );
}
