import { WebshopProduct } from '../../modules/apiTypes';
import FormattedPrice from '../general/FormattedPrice';
interface ProductPriceProps {
  product: WebshopProduct | undefined;
  displayVat?: boolean;
  isCheckout?: boolean;
}
export function ProductPrice({
  displayVat,
  product,
  isCheckout = false,
}: ProductPriceProps) {
  if (product !== undefined) {
    const {
      price_incl_vat,
      price_excl_vat,
      custom_price_incl_vat,
      custom_price_excl_vat,
    } = product;
    const price = !!displayVat ? price_incl_vat : price_excl_vat;
    const discountedPrice = !!displayVat
      ? custom_price_incl_vat
      : custom_price_excl_vat;
    return (
      <FormattedPrice
        price={Number(price)}
        discountedPrice={Number(discountedPrice ?? price)}
        isCheckout={isCheckout}
      />
    );
  }
  return null;
}
