import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import * as Sentry from '@sentry/nextjs';

function getValueFromLocalStorage<T>(key: string): T | undefined {
  const stored = localStorage.getItem(key);
  if (stored === null) {
    return undefined;
  }

  try {
    return JSON.parse(stored) as T | undefined;
  } catch (e) {
    Sentry.captureException(e);
  }
  return undefined;
}

export function useLocalStorageState<T>(
  key: string,
  defaultValue: T
): [T, Dispatch<SetStateAction<T>>, boolean] {
  const [initialized, setInitialized] = useState(false);
  const [state, setState] = useState<T>(() => defaultValue);

  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      const stored = getValueFromLocalStorage<T>(key);
      if (stored !== undefined) {
        setState(stored);
        return;
      }
    }
    localStorage.setItem(key, JSON.stringify(state));
  }, [state, key, initialized]);

  return [state, setState, initialized];
}
