import { ProductAmount, ValidatedDiscountCode } from '@/modules/apiTypes';
import { calculateTotalCost } from '@/utils/cartUtils';
import FormattedPrice from '../general/FormattedPrice';

interface TotalFormattedPriceProps {
  displayVat: boolean;
  productAmounts?: ProductAmount[];
  discountCode?: ValidatedDiscountCode;
  isCheckout?: boolean;
}

export function TotalFormattedPrice({
  displayVat,
  productAmounts,
  discountCode,
  isCheckout = false,
}: Readonly<TotalFormattedPriceProps>) {
  const cost = calculateTotalCost(
    displayVat,
    productAmounts,
    discountCode,
    isCheckout
  );
  return (
    <FormattedPrice
      price={cost.totalCost}
      discountedPrice={cost.totalDiscountedCost}
      isCheckout={isCheckout}
    />
  );
}
