import {
  ShoppingCartAction,
  useShoppingCart,
} from '@/context/shoppingCartContext';
import Image from 'next/image';
import Link from 'next/link';
import QuantitySelector from '@/components/general/QuantitySelector';
import TrashIcon from '@/icons/trash.svg';
import { ValidatedDiscountCode, WebshopProduct } from '@/modules/apiTypes';
import { ProductPrice } from '../pageStore/ProductPrice';
import { GtagItem, sendEcommerceEvent } from '@/modules/googleAnalytics';
import { useEffect, useState } from 'react';
import { calculateTotalCost } from '@/utils/cartUtils';
import { getCategoryTree } from '@/utils/productUtils';
import Media from './Media';
import { productUrl } from '@/modules/helper';
import FormattedPrice from './FormattedPrice';

interface CartProps {
  discountCode?: ValidatedDiscountCode;
  isCheckout?: boolean;
}

export default function Cart({
  discountCode,
  isCheckout = false,
}: Readonly<CartProps>) {
  const {
    displayVat,
    updateShoppingCart,
    categoryMap,
    shoppingCartProductAmount,
  } = useShoppingCart();

  const handleAmountChange = (
    oldAmount: number,
    newAmount: number,
    product: WebshopProduct
  ) => {
    if (newAmount > 0) {
      if (oldAmount < newAmount) {
        sendCartAnalyticsEvent('add_to_cart', newAmount - oldAmount, product);
      }

      if (oldAmount > newAmount) {
        sendCartAnalyticsEvent(
          'remove_from_cart',
          oldAmount - newAmount,
          product
        );
      }

      updateShoppingCart({
        type: ShoppingCartAction.MODIFY,
        payload: {
          amount: newAmount,
          id: product.id.toString(),
          name: product.name,
        },
      });
    } else {
      updateShoppingCart({
        type: ShoppingCartAction.MODIFY,
        payload: {
          amount: 0,
          id: product.id.toString(),
          name: product.name,
        },
      });
    }
  };

  const handleRemoveClick = (oldAmount: number, product: WebshopProduct) => {
    sendCartAnalyticsEvent('remove_from_cart', oldAmount, product);
    updateShoppingCart({
      type: ShoppingCartAction.DELETE,
      payload: { amount: 0, id: product.id.toString(), name: product.name },
    });
  };

  const sendCartAnalyticsEvent = (
    action: string,
    amount: number,
    product: WebshopProduct
  ) => {
    const categories = getCategoryTree(product, categoryMap);

    sendEcommerceEvent({
      name: action,
      value: calculateTotalCost(
        false,
        [{ amount: amount, product }],
        discountCode
      ).totalDiscountedCost,
      currency: 'SEK',
      items: [
        {
          item_id: product.id.toString(),
          item_name: product.name,
          price: Number(product.custom_price_excl_vat ?? 0),
          quantity: amount,
          item_category: categories.at(-1),
          item_category2: categories.at(-2),
          item_category3: categories.at(-3),
          item_category4: categories.at(-4),
          item_category5: categories.at(-5),
        },
      ],
    });
  };

  const [analyticsSent, setAnalyticsSent] = useState<boolean>(false);
  useEffect(() => {
    if (
      shoppingCartProductAmount &&
      shoppingCartProductAmount.length > 0 &&
      !analyticsSent
    ) {
      const gtagItemList: GtagItem[] = shoppingCartProductAmount.map(
        (productAmount) => {
          const categories = getCategoryTree(productAmount, categoryMap);
          return {
            item_id: productAmount.product.id.toString(),
            item_name: productAmount.product.name,
            price: Number(productAmount.product.custom_price_excl_vat ?? 0),
            quantity: productAmount.amount,
            item_category: categories.at(-1),
            item_category2: categories.at(-2),
            item_category3: categories.at(-3),
            item_category4: categories.at(-4),
            item_category5: categories.at(-5),
          };
        }
      );
      sendEcommerceEvent({
        name: 'view_cart',
        value: calculateTotalCost(
          false,
          shoppingCartProductAmount,
          discountCode
        ).totalDiscountedCost,
        currency: 'SEK',
        items: gtagItemList,
      });
      setAnalyticsSent(true);
    }
  }, [
    analyticsSent,
    displayVat,
    shoppingCartProductAmount,
    categoryMap,
    discountCode,
  ]);

  return (
    <>
      <div className="mx-auto max-w-page-width-mx-160 divide-y divide-solid divide-hlr-gunmetal/25 md:divide-transparent">
        {shoppingCartProductAmount ? (
          shoppingCartProductAmount.map((item) => {
            const productMedia = item.product.images.at(0);
            return (
              <div
                key={item.product.id}
                className="my-3 flex w-full flex-col justify-between pt-3 md:my-2 md:flex-row md:pt-0"
              >
                <Link
                  className="group flex flex-row"
                  href={productUrl(item.product)}
                >
                  {productMedia && (
                    <div className="relative h-20 w-20 shrink-0 transition-opacity group-hover:opacity-70 md:w-28">
                      <Media
                        media={productMedia}
                        imageAdditionalClasses="rounded border object-cover"
                        videoAdditionalClasses="h-full w-full rounded border object-cover"
                        sizes="(max-width: 768px) 25vw, 10vw"
                      />
                    </div>
                  )}
                  <div className="h-min px-6 transition-opacity group-hover:opacity-70 hover:underline md:py-2">
                    <p className="font-raleway font-semibold md:text-lg">
                      {item.product.name}
                    </p>
                  </div>
                </Link>

                <div className="my-auto mt-2 flex flex-row justify-end gap-4 md:mt-0 md:justify-between">
                  <div className="custom-item-price mt-auto text-lg md:my-auto md:text-right">
                    <ProductPrice
                      displayVat={displayVat}
                      product={item.product}
                      isCheckout={isCheckout}
                    />
                  </div>
                  <p className="pointer-events-none self-center text-gray-400">
                    &#x2715;
                  </p>
                  <div className="inline-flex">
                    <QuantitySelector
                      amount={item.amount}
                      handleIncrementClick={() =>
                        handleAmountChange(
                          item.amount,
                          item.amount + 1,
                          item.product
                        )
                      }
                      handleDecrementClick={() =>
                        handleAmountChange(
                          item.amount,
                          item.amount - 1,
                          item.product
                        )
                      }
                      handleAmountChange={(n) =>
                        handleAmountChange(item.amount, n, item.product)
                      }
                    />
                    <button
                      className="ml-4 flex h-8 w-8 items-center justify-center self-center rounded-full text-lg transition-colors hover:bg-hlr-gunmetal/20 md:text-xl"
                      onClick={() =>
                        handleRemoveClick(item.amount, item.product)
                      }
                    >
                      <Image
                        src={TrashIcon as string}
                        alt="trash"
                        width={24}
                        height={24}
                      />
                    </button>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div className="text-center text-xl">Din varukorg är tom</div>
        )}
      </div>
      <>
        {discountCode && (
          <div className="flex justify-between pt-2">
            <div className="h-min px-6 transition-opacity md:py-2">
              <p className="font-raleway font-semibold md:text-lg">
                {discountCode.discount_code_name}
              </p>
            </div>
            <FormattedPrice
              price={Number(
                displayVat
                  ? -discountCode.discount_amount_incl_vat
                  : -discountCode.discount_amount_excl_vat
              )}
              isCheckout={true}
              isDiscount={true}
            />
          </div>
        )}
      </>
    </>
  );
}
