import {
  WebshopCategory,
  WebshopFullCategory,
  WebshopProduct,
} from './apiTypes';

export function productUrl(product: WebshopProduct): string {
  return `/butik/produkt/${product.id}/${encodeURIComponent(product.slug)}`;
}

export function categoryUrl(
  category: WebshopCategory | WebshopFullCategory
): string {
  return `/butik/kategori/${category.id}/${encodeURIComponent(category.slug)}`;
}
