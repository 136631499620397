import { Dispatch, ReactElement, useState } from 'react';
import { Modal, ModalButton } from '@/components/general/Modal';
import { Formik } from 'formik';
import { TextField } from '@/components/general/TextFields';
import CompanyCheckoutSwitch from '@/components/pageStore/sectionHLRCheckout/partials/CompanyCheckoutSwitch';
import { registerUser, RegisterUserResponse } from '@/modules/apiClient';
import * as Yup from 'yup';
import CountrySelector from '@/components/general/CountrySelector';
import { RegisterUserRequest } from '@/modules/apiTypes';
import { StrapiRegistrationFormAttributes } from '@/modules/strapiTypes';
import Link from 'next/link';
import { ModalVisibility } from './NavbarModal';

export interface RegistrationValues {
  isCompany: boolean;
  companyName: string;
  organizationNumber: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  address: string;
  address2: string;
  zipCode: string;
  city: string;
  country: string;
}

const initialValues: RegistrationValues = {
  isCompany: true,
  companyName: '',
  organizationNumber: '',
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  address: '',
  address2: '',
  zipCode: '',
  city: '',
  country: 'SE',
};

function getValidationSchema(texts: StrapiRegistrationFormAttributes) {
  return Yup.object({
    isCompany: Yup.bool().required(texts?.required_field_error_message),
    companyName: Yup.string().when('isCompany', {
      is: true,
      then: (s) => s.required(texts?.required_field_error_message),
    }),
    organizationNumber: Yup.string().when('isCompany', {
      is: true,
      then: (s) => s.required(texts?.required_field_error_message),
    }),
    firstName: Yup.string().required(texts?.required_field_error_message),
    lastName: Yup.string().required(texts?.required_field_error_message),
    email: Yup.string()
      .email(texts?.invalid_email_error_message)
      .required(texts?.required_field_error_message),
    phoneNumber: Yup.string(),
    address: Yup.string(),
    address2: Yup.string(),
    zipCode: Yup.string(),
    city: Yup.string(),
    country: Yup.string(),
  });
}

interface RegistrationModalProps {
  onHide: () => void;
  setVisibility: Dispatch<ModalVisibility>;
  texts: StrapiRegistrationFormAttributes;
}

export function RegistrationModal({
  onHide,
  setVisibility,
  texts,
}: RegistrationModalProps): ReactElement | null {
  const [registerUserResult, setRegisterUserResult] =
    useState<RegisterUserResponse>();
  const [userEmail, setUserEmail] = useState<string>();

  const handleSubmit = async (values: RegistrationValues) => {
    setRegisterUserResult(undefined);
    setUserEmail(values.email);
    const data: RegisterUserRequest = {
      email: values.email,
      customer: {
        customer_type: values.isCompany ? 'company' : 'private',
        first_name: values.firstName,
        last_name: values.lastName,
        company_name: values.isCompany ? values.companyName : '',
        organization_number: values.isCompany ? values.organizationNumber : '',
        phone_number: values.phoneNumber,
        country: values.country,
        address: values.address,
        address2: values.address2,
        zip_code: values.zipCode,
        city: values.city,
      },
    };
    const result = await registerUser(data);
    setRegisterUserResult(result);
  };

  if (registerUserResult === 'success') {
    return (
      <SuccessModal texts={texts} email={userEmail ?? ''} onHide={onHide} />
    );
  }

  const errorMessage = {
    success: '',
    failed: texts?.registration_failed_generic_message,
    conflict: texts?.registration_failed_conflict_message,
  }[registerUserResult ?? 'success'];

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={getValidationSchema(texts)}
      validateOnBlur={true}
      enableReinitialize={true}
      validateOnMount={true}
    >
      {({
        values,
        setFieldValue,
        submitForm,
        isSubmitting,
        dirty,
        isValid,
      }) => {
        const formDisabled = isSubmitting || !dirty || !isValid;

        return (
          <Modal
            onHide={onHide}
            title={texts?.title ?? 'Skapa konto'}
            footer={
              <div className="flex w-full items-center justify-between">
                <div className="text-custom-red">{errorMessage}</div>
                <div className="shrink-0">
                  <ModalButton
                    text={texts?.create_button_label ?? 'Skapa konto'}
                    disabled={formDisabled}
                    onClick={() => void submitForm()}
                  />
                </div>
              </div>
            }
          >
            <div className="px-0 md:px-8">
              <p className="pb-4">
                {texts?.login_text + ' '}
                <a
                  className="font-bold hover:underline"
                  onClick={() => setVisibility(ModalVisibility.Login)}
                  href="#"
                >
                  {texts?.login_link_label}
                </a>
              </p>
              <CompanyCheckoutSwitch
                companyCheckout={values.isCompany}
                setValue={(value) => setFieldValue('isCompany', value)}
                companyCustomerLabel={texts?.company_registration_label ?? ''}
                privateCustomerLabel={texts?.private_registration_label ?? ''}
              />
              {values.isCompany && (
                <>
                  <TextField
                    label={texts?.label_company_name}
                    name="companyName"
                    isRequired={true}
                    autoComplete="organization"
                  />
                  <TextField
                    label={texts?.label_organization_name}
                    name="organizationNumber"
                    isRequired={true}
                  />
                </>
              )}
              <div className="flex gap-2">
                <TextField
                  label={texts?.label_first_name}
                  name="firstName"
                  isRequired={true}
                  autoComplete="given-name"
                />
                <TextField
                  label={texts?.label_last_name}
                  name="lastName"
                  isRequired={true}
                  autoComplete="family-name"
                />
              </div>
              <TextField
                label={texts?.label_email}
                name="email"
                type="email"
                isRequired={true}
                autoComplete="email"
              />
              <TextField
                label={texts?.label_phone_number}
                name="phoneNumber"
                autoComplete="tel"
              />
              <TextField
                label={texts?.label_address_primary}
                name="address"
                autoComplete="address-line1"
              />
              <TextField
                label={texts?.label_address_secondary}
                name="address2"
                autoComplete="address-line2"
              />
              <div className="flex gap-2">
                <TextField
                  label={texts?.label_postal_code}
                  name="zipCode"
                  autoComplete="postal-code"
                />
                <TextField
                  label={texts?.label_locality}
                  name="city"
                  autoComplete="address-level2"
                />
              </div>
              <CountrySelector
                name="country"
                label={texts?.label_country}
                locale="sv"
                autoComplete="country"
              />
              <p className="text-sm">
                {texts?.terms_text + ' '}
                <Link
                  className="font-bold hover:underline"
                  href={texts?.terms_link.url ?? '/'}
                  rel={texts?.terms_link.rel}
                  target={
                    texts?.terms_link.open_in_new_tab ? '_blank' : '_self'
                  }
                >
                  {texts?.terms_link.display_name}
                </Link>
              </p>
            </div>
          </Modal>
        );
      }}
    </Formik>
  );
}

interface SuccessModalProps {
  onHide: () => void;
  email: string;
  texts: StrapiRegistrationFormAttributes;
}

function SuccessModal({
  onHide,
  email,
  texts,
}: SuccessModalProps): ReactElement | null {
  return (
    <Modal
      onHide={onHide}
      title={texts?.title ?? 'Skapa konto'}
      footer={
        <div className="flex w-full justify-end">
          <ModalButton
            text={texts?.close_button_label ?? 'Stäng'}
            onClick={onHide}
          />
        </div>
      }
    >
      <div className="px-0 md:px-8">
        <p className="pb-4 text-lg font-bold">
          {texts?.registration_success_header}
        </p>
        <p>
          {texts?.registration_success_description.replaceAll('{email}', email)}
        </p>
      </div>
    </Modal>
  );
}
