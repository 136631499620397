import {
  useShoppingCart,
  WebshopCategoryMap,
  WebshopProductMap,
} from '../context/shoppingCartContext';
import {
  WebshopCategory,
  WebshopFullCategory,
  WebshopProduct,
} from '@/modules/apiTypes';
import { categoryUrl, productUrl } from '@/modules/helper';
import { PathData } from '@/strapiComponents/HLRStore/HLRStore';

export function getCategoryPath(
  id: string,
  categoryMap: WebshopCategoryMap
): PathData[] {
  const result: PathData[] = [];

  function traverseCategory(id: string) {
    const category = categoryMap.get(id);

    if (category) {
      result.unshift({
        id: category.id,
        name: category.menu_name || category.name,
        url: categoryUrl(category),
        isProduct: false,
      });
      if (category.parent_category) {
        traverseCategory(category.parent_category.toString());
      }
    }
  }

  traverseCategory(id);
  return result;
}

export function getProductPath(
  product: WebshopProduct,
  categoryMap: WebshopCategoryMap,
  currentProductParentCategory: string
): PathData[] {
  const results: PathData[][] = [];
  product.categories.forEach((category) =>
    results.push(getCategoryPath(category, categoryMap))
  );
  const result = currentProductParentCategory
    ? results.find((result) =>
        result.find(({ id }) => id === currentProductParentCategory)
      )
    : results[0];
  result?.push({
    id: product.id.toString(),
    name: product.menu_name || product.name,
    url: productUrl(product),
    isProduct: true,
  });
  return result ? result : [];
}

export function mapProducts(products: WebshopProduct[]): WebshopProductMap {
  const productsMap = new Map<string, WebshopProduct>();

  products.forEach((product) => {
    productsMap.set(product.id.toString(), product);
  });

  return productsMap;
}

export function mapCategories(
  categories: WebshopCategory[]
): WebshopCategoryMap {
  const categoriesMap = new Map<string, WebshopCategory>();

  categories.forEach((category) => {
    categoriesMap.set(category.id, category);
  });

  return categoriesMap;
}

export function useCategoriesWithFallback(
  fallback: WebshopCategory[]
): WebshopCategoryMap {
  const { categoryMap } = useShoppingCart();
  return categoryMap.size > 0 ? categoryMap : mapCategories(fallback);
}

export function productDescriptionToElement(description: string): string {
  if (typeof document !== 'undefined') {
    const element = document.createElement('div');
    element.innerHTML = description;
    const hostname = window.location.hostname;
    // Open external links to a new tab
    for (const a of element.getElementsByTagName('a')) {
      if (!a.href.includes(hostname)) {
        a.target = '_blank';
        a.rel = 'noopener noreferrer';
      }
    }
    return element.innerHTML;
  }
  return description;
}

export function getAllProductsInCategoryTree(
  root: WebshopFullCategory
): WebshopProduct[] {
  const stack = [root];
  const products: WebshopProduct[] = [];
  while (stack.length > 0) {
    const category = stack.pop();
    products.push(...(category?.products ?? []));
    stack.push(...(category?.subcategories ?? []));
  }

  return products;
}
