import Cart from '@/components/general/Cart';
import Link from 'next/link';
import { useShoppingCart } from '@/context/shoppingCartContext';
import { StrapiCartModalAttributes } from '@/modules/strapiTypes';
import { TotalFormattedPrice } from '@/components/pageStore/TotalFormattedPrice';
import PriceConfigurationDropDown from '../PriceConfigurationDropDown';

interface CartModalData {
  onHide: () => void;
  texts: StrapiCartModalAttributes;
}

export default function CartModal({ onHide, texts }: Readonly<CartModalData>) {
  const { noItemsInCart, displayVat, shoppingCartProductAmount } =
    useShoppingCart();

  return (
    <div
      className="fixed inset-0 z-50 bg-custom-darkgrey/50 md:px-default"
      onClick={onHide}
    >
      <div
        className="mx-auto h-full max-w-page-width-mx-560 bg-white md:mt-16vh md:h-auto md:p-8"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="fixed top-0 flex h-20 w-full flex-col border-b border-hlr-gunmetal px-6 pt-4 md:static md:h-auto md:flex-row md:items-center md:justify-between md:px-0 md:pb-6 md:pt-0">
          <div className="flex flex-row justify-between">
            <div className="mr-auto font-raleway text-xl md:text-3xl">
              {texts?.title}
            </div>
            <button onClick={onHide} className="text-2xl md:hidden">
              &#x2715;
            </button>
          </div>
          <div className="flex gap-2">
            <PriceConfigurationDropDown
              showPriceText={texts?.label_vat_options}
              priceIncludingVatText={texts?.label_vat_included}
              priceExcludingVatText={texts?.label_vat_excluded}
            />
            <button
              onClick={onHide}
              className="ml-4 hidden md:block md:text-3xl"
            >
              &#x2715;
            </button>
          </div>
        </div>
        <div className="fixed inset-y-20 w-full overflow-y-scroll overscroll-contain px-10 md:static md:max-h-50vh md:overflow-auto md:px-4 md:py-10">
          <Cart />
        </div>
        <div className="fixed bottom-0 flex h-20 w-full items-center justify-between border-t border-hlr-gunmetal px-6 pb-4 pt-6 md:static md:h-auto md:gap-8 md:px-0 md:pb-0">
          <div className="flex w-full flex-wrap justify-between gap-x-8 gap-y-2">
            <div className="my-auto flex flex-row items-center gap-2 font-raleway md:text-xl">
              <h2 className="mb-2 text-base font-light md:self-end md:text-32px">
                {texts?.label_total_price}
              </h2>
              <h2 className="font-mulish text-lg font-bold md:text-32px">
                <TotalFormattedPrice
                  displayVat={displayVat}
                  productAmounts={shoppingCartProductAmount}
                />
              </h2>
              <h2 className="mb-2 self-end text-base font-light md:self-end md:text-32px">
                {displayVat
                  ? texts?.label_vat_included
                  : texts?.label_vat_excluded}
              </h2>
            </div>
            <div className="my-auto hidden flex-row items-center gap-2 font-raleway md:flex">
              <div className="self-end text-sm md:text-lg">
                {displayVat
                  ? texts?.label_vat_excluded
                  : texts?.label_vat_included + ':'}
              </div>
              <div className="font-mulish text-sm font-bold md:text-lg">
                <TotalFormattedPrice
                  displayVat={!displayVat}
                  productAmounts={shoppingCartProductAmount}
                />
              </div>
            </div>
          </div>
          <div className="mb-2 flex flex-col gap-2 lg:mb-0 lg:flex-row lg:gap-6">
            <button
              onClick={onHide}
              className="select-none whitespace-nowrap rounded-full bg-hlr-gunmetal px-3 py-1.5 text-sm font-semibold text-white transition-opacity hover:opacity-60 md:px-6 md:py-3 md:text-lg"
            >
              {texts?.label_continue_shopping_button}
            </button>
            <Link
              href="/butik/kassa"
              className={`${
                noItemsInCart
                  ? 'hover:cursor-not-allowed'
                  : 'pointer-events-none opacity-25'
              }`}
              onClick={onHide}
            >
              <button className="select-none whitespace-nowrap rounded-full bg-custom-green px-3 py-1.5 text-sm font-semibold text-white transition-opacity hover:opacity-60 md:px-6 md:py-3 md:text-lg">
                {texts?.label_checkout_button}
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
