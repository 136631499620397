import { ModalVisibility } from '@/strapiComponents/NavbarModal/NavbarModal';
import React, { createContext, useContext, useState, ReactNode } from 'react';

interface NavbarModalContextProps {
  navbarModalVisible: ModalVisibility;
  setNavbarModalVisible: (visibility: ModalVisibility) => void;
}

const NavbarModalContext = createContext<NavbarModalContextProps | undefined>(
  undefined
);

const NavbarModalsContext: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [navbarModalVisible, setNavbarModalVisible] = useState<ModalVisibility>(
    ModalVisibility.None
  );

  return (
    <NavbarModalContext.Provider
      value={{ navbarModalVisible, setNavbarModalVisible }}
    >
      {children}
    </NavbarModalContext.Provider>
  );
};

export const useNavbarModal = () => {
  const context = useContext(NavbarModalContext);
  if (!context) {
    throw new Error('useNavbarModal must be used within a NavbarModalProvider');
  }
  return context;
};

export { NavbarModalsContext };
