import { WebshopCategory } from '@/modules/apiTypes';

/// Client side cache for `categories` request

let categoryCache: WebshopCategory[] | null = null;

export function getCachedCategories(): typeof categoryCache {
  return categoryCache;
}

export function setCachedCategories(categories: WebshopCategory[]) {
  categoryCache = categories;
}
