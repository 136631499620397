import { Dispatch, ReactElement, useState } from 'react';
import { Modal, ModalButton } from '@/components/general/Modal';
import { Formik } from 'formik';
import { TextField } from '@/components/general/TextFields';
import { resetPassword } from '@/modules/apiClient';
import * as Yup from 'yup';
import { ModalVisibility } from './NavbarModal';
import { StrapiResetPasswordFormAttributes } from '@/modules/strapiTypes';

function getValidationSchema(texts: StrapiResetPasswordFormAttributes) {
  return Yup.object({
    email: Yup.string()
      .email(texts?.invalid_email_error_message)
      .required(texts?.required_field_error_message),
  });
}

export interface ResetPasswordValues {
  email: string;
}

const initialValues: ResetPasswordValues = {
  email: '',
};

interface ResetPasswordModalProps {
  onHide: () => void;
  setVisibility: Dispatch<ModalVisibility>;
  texts: StrapiResetPasswordFormAttributes;
}

export function ResetPasswordModal({
  onHide,
  setVisibility,
  texts,
}: ResetPasswordModalProps): ReactElement | null {
  const [resetPasswordSuccessful, setResetPasswordSuccessful] = useState(false);

  const handleSubmit = async ({ email }: ResetPasswordValues) => {
    await resetPassword({ email });
    setResetPasswordSuccessful(true);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={getValidationSchema(texts)}
      validateOnMount={true}
    >
      {({ submitForm, isSubmitting, isValid }) => (
        <Modal
          onHide={onHide}
          title={texts?.title ?? 'Återställ lösenord'}
          footer={
            <div className="shrink-0">
              {resetPasswordSuccessful ? (
                <ModalButton
                  text={texts?.close_button_label ?? 'Stäng'}
                  onClick={onHide}
                />
              ) : (
                <ModalButton
                  text={texts?.submit_button_label ?? 'Skicka'}
                  disabled={isSubmitting || !isValid}
                  onClick={() => void submitForm()}
                />
              )}
            </div>
          }
        >
          <div className="px-0 md:px-8">
            {resetPasswordSuccessful ? (
              <p>{texts?.password_reset_sent_text}</p>
            ) : (
              <>
                <p className="mb-4 text-lg font-semibold">{texts?.header}</p>
                <p className="mb-4">
                  {texts?.login_text + ' '}
                  <a
                    className="font-bold hover:underline"
                    onClick={() => setVisibility(ModalVisibility.Login)}
                    href="#"
                  >
                    {texts?.login_link_label}
                  </a>
                </p>
                <div className="flex flex-col gap-2">
                  <TextField
                    label={texts?.email_label}
                    name="email"
                    isRequired={true}
                    autoComplete="email"
                  />
                </div>
              </>
            )}
          </div>
        </Modal>
      )}
    </Formik>
  );
}
