interface FormattedPriceProps {
  price: number;
  discountedPrice?: number;
  isCheckout?: boolean;
  isDiscount?: boolean;
}

export default function FormattedPrice({
  price,
  discountedPrice,
  isCheckout = false,
  isDiscount = false,
}: FormattedPriceProps) {
  return (
    <Price
      price={price}
      discountedPrice={discountedPrice ?? price}
      isCheckout={isCheckout}
      isDiscount={isDiscount}
    />
  );
}

interface PriceProps {
  price: number;
  discountedPrice: number;
  isCheckout?: boolean;
  isDiscount?: boolean;
}

const numberFormat = new Intl.NumberFormat('sv-SE', {
  style: 'currency',
  currency: 'SEK',
  maximumFractionDigits: 0,
});

const decimalNumberFormat = new Intl.NumberFormat('sv-SE', {
  style: 'currency',
  currency: 'SEK',
  maximumFractionDigits: 2,
});

const hasDecimals = (price: number): boolean => price % 1 !== 0;

function Price({
  price,
  discountedPrice,
  isCheckout = false,
  isDiscount = false,
}: PriceProps) {
  const hasDiscount = Math.trunc(discountedPrice) !== Math.trunc(price);
  return (
    <div className="flex flex-col">
      <div
        className={`text-caption -mb-1.5 text-custom-grey ${
          hasDiscount ? 'line-through' : ''
        }`}
      >
        {hasDiscount
          ? isCheckout
            ? (hasDecimals(price) ? decimalNumberFormat : numberFormat).format(
                price
              )
            : numberFormat.format(price)
          : '\u00A0'}
      </div>
      <div className={`leading-normal ${isDiscount ? 'text-red-500' : ''}`}>
        {isCheckout
          ? (hasDecimals(discountedPrice)
              ? decimalNumberFormat
              : numberFormat
            ).format(discountedPrice)
          : numberFormat.format(discountedPrice)}
      </div>
    </div>
  );
}
