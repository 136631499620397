import { FocusEventHandler, ReactElement, useState } from 'react';
import { Field, FieldProps } from 'formik';
import EyeOpen from '@/icons/eye.svg';
import EyeSlash from '@/icons/eye-slash.svg';
import Image from 'next/image';

interface TextFieldProps {
  label?: string;
  type?: string;
  isRequired?: boolean;
  autoComplete?: string;
  name: string;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value: string;
  touched?: boolean;
  error?: string;
  disabled?: boolean;
  noError?: boolean;
  extraClassNames?: string;
  placeholder?: string;
  autoFocus?: boolean;
}

export function TextField({
  label,
  name,
  type = 'text',
  isRequired = false,
  autoComplete,
  onBlur,
  onChange,
  value,
  touched,
  error,
  disabled,
  noError,
  extraClassNames,
  placeholder,
  autoFocus,
}: TextFieldProps): ReactElement {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="w-full">
      {label && (
        <p className="mb-1 block">
          {label + (isRequired ? ' *' : '')}
          {!noError && (
            <span className="ml-1 text-custom-red">
              {touched && error}&nbsp;
            </span>
          )}
        </p>
      )}
      <div className="relative">
        <input
          className={`w-full rounded border border-hlr-form-border bg-white p-2 text-lg ${
            extraClassNames ?? ''
          } ${disabled ? 'cursor-not-allowed bg-zinc-100' : ''}`}
          type={showPassword ? 'text' : type}
          autoComplete={autoComplete}
          name={name}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          required={isRequired}
          disabled={disabled}
          placeholder={placeholder}
          autoFocus={autoFocus}
        />
        {type === 'password' && (
          <button
            type="button"
            className="absolute right-2 h-full focus:outline-none"
            onClick={togglePasswordVisibility}
            tabIndex={-1}
          >
            {showPassword ? (
              <Image
                src={EyeSlash as string}
                alt="Hide"
                height="24"
                width="24"
                title={'Hide'}
              />
            ) : (
              <Image
                src={EyeOpen as string}
                alt="Show"
                height="24"
                width="24"
                title={'Show'}
              />
            )}
          </button>
        )}
      </div>
    </div>
  );
}

interface FormikTextFieldProps extends Partial<TextFieldProps> {
  name: string;
}

export function FormikTextField<ValueType>(props: FormikTextFieldProps) {
  return (
    <Field name={props.name}>
      {({ field, meta }: FieldProps<string, ValueType>) => (
        <TextField {...{ ...field, ...meta, ...props }} />
      )}
    </Field>
  );
}
