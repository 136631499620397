import { Dispatch, ReactElement, useEffect, useState } from 'react';
import { LoginModal } from './LoginModal';
import { ResetPasswordModal } from './ResetPasswordModal';
import { RegistrationModal } from './RegistrationModal';
import {
  getCartModal,
  getLoginForm,
  getRegistrationForm,
  getResetPasswordForm,
} from '@/modules/strapiApiClient';
import {
  StrapiCartModalAttributes,
  StrapiLoginFormAttributes,
  StrapiRegistrationFormAttributes,
  StrapiResetPasswordFormAttributes,
} from '@/modules/strapiTypes';
import CartModal from './CartModal';

export enum ModalVisibility {
  None,
  Login,
  Registration,
  PasswordReset,
  Cart,
}

interface NavbarModalProps {
  visibility: ModalVisibility;
  setVisibility: Dispatch<ModalVisibility>;
}

export function NavbarModal({
  visibility,
  setVisibility,
}: Readonly<NavbarModalProps>): ReactElement | null {
  const [loginTexts, setLoginTexts] = useState<StrapiLoginFormAttributes>();
  const [resetTexts, setResetTexts] =
    useState<StrapiResetPasswordFormAttributes>();
  const [registrationTexts, setRegistrationTexts] =
    useState<StrapiRegistrationFormAttributes>();
  const [cartModalTexts, setCartModalTexts] =
    useState<StrapiCartModalAttributes>();

  useEffect(() => {
    const fetchData = async () => {
      const loginData = await getLoginForm();
      const resetData = await getResetPasswordForm();
      const registrationData = await getRegistrationForm();
      const cartModalData = await getCartModal();
      setLoginTexts(loginData?.attributes);
      setResetTexts(resetData?.attributes);
      setRegistrationTexts(registrationData?.attributes);
      setCartModalTexts(cartModalData?.attributes);
    };

    void fetchData();
  }, []);

  return (
    <>
      {visibility === ModalVisibility.Login && (
        <LoginModal
          onHide={() => setVisibility(ModalVisibility.None)}
          setVisibility={setVisibility}
          texts={loginTexts}
        />
      )}
      {visibility === ModalVisibility.PasswordReset && (
        <ResetPasswordModal
          onHide={() => setVisibility(ModalVisibility.None)}
          setVisibility={setVisibility}
          texts={resetTexts}
        />
      )}
      {visibility === ModalVisibility.Registration && (
        <RegistrationModal
          onHide={() => setVisibility(ModalVisibility.None)}
          setVisibility={setVisibility}
          texts={registrationTexts}
        />
      )}
      {visibility === ModalVisibility.Cart && (
        <CartModal
          onHide={() => setVisibility(ModalVisibility.None)}
          texts={cartModalTexts}
        />
      )}
    </>
  );
}
