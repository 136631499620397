import { WebshopCategoryMap } from '../context/shoppingCartContext';
import { ProductAmount, WebshopProduct } from '../modules/apiTypes';

export function getCategoryTree(
  product: ProductAmount | WebshopProduct,
  categoryMap: WebshopCategoryMap
): string[] {
  const productCategoryId =
    'id' in product
      ? product.categories.at(0)
      : product.product.categories.at(0);
  if (productCategoryId === undefined) {
    return [];
  }
  let category = categoryMap.get(productCategoryId);
  const categoryNames = [];
  while (category !== undefined) {
    categoryNames.push(category.name);
    category = categoryMap.get(category.parent_category?.toString() ?? '');
  }
  return categoryNames;
}
