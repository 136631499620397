import { WebshopProductMap } from '@/context/shoppingCartContext';
import {
  Customer,
  WebshopFullCategory,
  WebshopProduct,
} from '@/modules/apiTypes';
import { getProductsRequest } from '@/modules/apiClient';

/// Client side cache for product map

const productMapCache = new Map<string, WebshopProductMap>();

export async function getProducts(
  productIDs: string[],
  customer: Customer | undefined,
  authToken: string | undefined,
  includeAllDescriptions?: boolean,
  includeBundledProductData?: boolean,
  includeInvisible?: boolean
): Promise<WebshopProduct[]> {
  const productMap = getCustomerPriceMap(customer);
  const missingIDs = productIDs.filter((id) => !productMap.has(id));
  if (missingIDs.length > 0) {
    const prods = await getProductsRequest(
      missingIDs,
      customer,
      authToken,
      includeAllDescriptions,
      includeBundledProductData,
      includeInvisible
    );
    prods?.map((p) => productMap.set(p.id.toString(), p));
  }

  setCustomerPriceMap(customer, productMap);
  return productIDs
    .map((id) => productMap.get(id) as WebshopProduct)
    .filter((p) => p);
}

function setCustomerPriceMap(
  customer: Customer | undefined,
  productMap: WebshopProductMap
) {
  const mapKey = getCustomerMapKey(customer);
  productMapCache.set(mapKey, productMap);
}

function getCustomerPriceMap(
  customer: Customer | undefined
): WebshopProductMap {
  const mapKey = getCustomerMapKey(customer);
  return productMapCache.get(mapKey) ?? new Map<string, WebshopProduct>();
}

function getCustomerMapKey(customer?: Customer) {
  return customer?.id ?? 'base';
}

export function addProductsToMapCache(
  fullCategory: WebshopFullCategory,
  customer?: Customer
) {
  const map = getCustomerPriceMap(customer);
  fullCategory.products?.map((p) => map.set(p.id.toString(), p));
  setCustomerPriceMap(customer, map);
  fullCategory.subcategories?.map((subCat) =>
    addProductsToMapCache(subCat, customer)
  );
}
