import { Dispatch, ReactElement } from 'react';

interface ModalProps {
  onHide: () => void;
  title: string;
  children?: ReactElement;
  footer?: ReactElement;
  wide?: boolean;
}

export function Modal({
  title,
  children,
  footer,
  onHide,
  wide = false,
}: ModalProps): ReactElement | null {
  return (
    <div
      className="fixed inset-0 z-20 flex h-screen items-center bg-custom-darkgrey/50"
      onClick={onHide}
    >
      <div
        className={`z-30 mx-auto flex max-h-90vh w-full flex-col bg-white p-10 ${
          wide ? 'max-w-page-width-mx-560' : 'max-w-650px'
        }`}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="flex items-center border-b border-hlr-gunmetal pb-4 md:pb-6">
          <div className="mr-auto font-raleway text-xl md:text-3xl">
            {title}
          </div>
          <button onClick={onHide} className="text-3xl">
            &#x2715;
          </button>
        </div>
        <div className="overflow-auto overscroll-contain py-4 pr-4 md:py-10 md:pr-0">
          {children}
        </div>
        {footer && (
          <div className="flex items-center justify-between border-t border-hlr-gunmetal pt-6">
            {footer}
          </div>
        )}
      </div>
    </div>
  );
}

export interface ModalButtonProps {
  onClick: Dispatch<void>;
  text: string;
  disabled?: boolean;
}

export function ModalButton({ text, onClick, disabled }: ModalButtonProps) {
  const bgColor = !!disabled ? 'bg-custom-grey' : 'bg-hlr-gunmetal';
  const classes = `rounded-full ${bgColor} px-6 py-3 text-lg font-semibold text-white`;

  return (
    <button className={classes} onClick={() => onClick()} disabled={!!disabled}>
      {text}
    </button>
  );
}
