import { Dispatch } from 'react';

interface CompanyCheckoutSwitchProps {
  companyCheckout: boolean;
  setValue: Dispatch<boolean>;
  companyCustomerLabel: string;
  privateCustomerLabel: string;
}

export default function CompanyCheckoutSwitch({
  companyCheckout,
  setValue,
  companyCustomerLabel,
  privateCustomerLabel,
}: CompanyCheckoutSwitchProps) {
  return (
    <div className="mb-5 flex w-full cursor-pointer flex-row rounded-xl border border-hlr-gunmetal text-center font-bold">
      <h3
        onClick={() => setValue(true)}
        className={`${
          companyCheckout ? 'bg-hlr-green text-white' : ''
        } my-auto flex w-1/2 justify-around rounded-l-xl py-2 md:py-4`}
      >
        {companyCustomerLabel}
      </h3>
      <h3
        className={`${
          !companyCheckout ? 'bg-hlr-green text-white' : ''
        } my-auto flex w-1/2 justify-around rounded-r-xl py-2 md:py-4`}
        onClick={() => setValue(false)}
      >
        {privateCustomerLabel}
      </h3>
    </div>
  );
}
