import { ProductAmount, ValidatedDiscountCode } from '@/modules/apiTypes';
import { useShoppingCart } from '@/context/shoppingCartContext';

export function calculateTotalCost(
  inclVat: boolean,
  productAmount: ProductAmount[] | undefined,
  discount: ValidatedDiscountCode | undefined,
  isCheckout?: boolean
) {
  const discountCodeAmount = inclVat
    ? discount?.discount_amount_incl_vat
    : discount?.discount_amount_excl_vat;

  const totalCost =
    productAmount?.reduce((sum, { amount, product }) => {
      const productPrice = inclVat
        ? product.price_incl_vat ?? '0'
        : product.price_excl_vat ?? '0';
      return (
        sum +
        amount *
          (isCheckout
            ? parseFloat(productPrice)
            : Math.round(parseFloat(productPrice)))
      );
    }, 0) ?? 0 - parseFloat(discountCodeAmount ?? '0');

  const totalDiscountedCost =
    (productAmount?.reduce((sum, { amount, product }) => {
      const productPrice = inclVat
        ? product.custom_price_incl_vat ?? product.price_incl_vat ?? '0'
        : product.custom_price_excl_vat ?? product.price_excl_vat ?? '0';
      return (
        sum +
        amount *
          (isCheckout
            ? parseFloat(productPrice)
            : Math.round(parseFloat(productPrice)))
      );
    }, 0) ?? 0) - parseFloat(discountCodeAmount ?? '0');

  return {
    totalCost: totalCost > 0 ? totalCost : 0,
    totalDiscountedCost: totalDiscountedCost > 0 ? totalDiscountedCost : 0,
  };
}

export function useTotalCostWithShipping(
  inclVat: boolean,
  discountCode?: ValidatedDiscountCode | undefined
) {
  const { shoppingCartProductAmount, selectedShippingAlternative } =
    useShoppingCart();
  const productAmount = shoppingCartProductAmount && [
    ...shoppingCartProductAmount,
  ];

  const totalCostExclShipping = calculateTotalCost(
    inclVat,
    productAmount,
    discountCode,
    true
  );

  const shippingPrice = inclVat
    ? parseFloat(selectedShippingAlternative?.product.price_incl_vat ?? '0')
    : parseFloat(selectedShippingAlternative?.product.price_excl_vat ?? '0');

  const customShippingPrice = inclVat
    ? parseFloat(
        selectedShippingAlternative?.product.custom_price_incl_vat ??
          selectedShippingAlternative?.product.price_incl_vat ??
          '0'
      )
    : parseFloat(
        selectedShippingAlternative?.product.custom_price_excl_vat ??
          selectedShippingAlternative?.product.price_excl_vat ??
          '0'
      );

  return {
    totalCost: totalCostExclShipping.totalCost + shippingPrice,
    totalDiscountedCost:
      totalCostExclShipping.totalDiscountedCost + customShippingPrice,
  };
}
